(function () {
    angular.module('PWAPoCApp').factory('errorInterceptorService', errorInterceptorService);

    errorInterceptorService.$inject = ['$rootScope', '$q', '$log', 'errorInterceptorIgnoreList'];

    function errorInterceptorService($rootScope, $q, $log, errorInterceptorIgnoreList) {
        return {
            responseError: responseError
        };

        function responseError(response) {
            const deferred = $q.defer();
            const log = {
                data: response.data,
                headers: response.config.headers,
                method: response.config.method,
                status: response.status,
                statusText: response.statusText,
                url: response.config.url
            };
            const ignoreList = errorInterceptorIgnoreList.ignoreList;

            if (!navigator.onLine ||
                !$rootScope.isOnline ||
                ignoreList.some(el => log.url.includes(el))) {

                deferred.resolve(response);
                return deferred.promise;
                
            }

            if (log.status >= 400 && log.status < 600){
                $log.error(log);
            }

            deferred.resolve(response);
            return deferred.promise;
            
        }
    }
})();
