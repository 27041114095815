(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('kWindowUtil', kWindowUtil);

    kWindowUtil.$inject = ['$rootScope'];

    function kWindowUtil($rootScope) {

        const retryCountLow = 7;
        const retryCountHigh = 20;
        const intervalLengthMs = 150;

        const service = {
            setModalClosability: setModalClosability
        };

        return service;

        function setModalClosability(closable){
            const display = closable ? 'inline-block' : 'none';
            const maxTries = closable ? retryCountLow : retryCountHigh;
            let tries = 0;

            const interval = setInterval(() => {

                const els = document.querySelectorAll('.k-window .k-window-titlebar .k-window-actions');

                if (els.length){
                    els.forEach(el => {
                        el.style.display = display;
                    })
                    clearInterval(interval);
                } else if (tries < maxTries){
                    tries++;
                } else {
                    clearInterval(interval);
                }
                
            }, intervalLengthMs);

            $rootScope.overlayDisabled = !closable;
        }

    }
})();
