(function () {
    'use strict';

    angular.module('PWAPoCApp').controller('CameraModalController', cameraModalController);

    cameraModalController.$inject = [
        '$q',
        '$scope',
        '$rootScope',
        '$windowInstance',
        '$timeout',
        '$log',
        'videoMode',
        'image',
        'viewPhotoOnly',
        'enableImageModalOpenCallback',
        'cameraSettings'
    ];

    function cameraModalController(
        $q,
        $scope,
        $rootScope,
        $windowInstance,
        $timeout,
        $log,
        videoMode,
        image,
        viewPhotoOnly,
        enableImageModalOpenCallback,
        cameraSettings
        ) {

        const photoSize = {
            height: cameraSettings.photo.height,
            width: cameraSettings.photo.width
        }

        var videoId = 'avvikVideoDiv',
            canvasId = 'takePhotoCanvas',
            imageInputId = 'image-upload',
            stream = null,
            recentlyTakenPhotoSrc = null,
            photoTaken = false;

        $scope.photoButtonText = 'Ta et bilde';
        $scope.isPhotoVisible = false;
        $scope.isPhoneOrTablet = null;
        

        $scope.viewPhotoOnly = viewPhotoOnly;

        $scope.hasImage = function () {
            return image || photoTaken;
        }

        $scope.close = function () {
            stopStream();
            $windowInstance.close();
        };
        $rootScope.$on('deviationWindowClosed',
            () => {
                $windowInstance.close(false);
            });

        $scope.save = function () {
            if ($scope.hasImage()) {
                const imageSource = videoMode ? recentlyTakenPhotoSrc : URL.createObjectURL(image);
                imageResizeAndConvertToBase64(imageSource).then(function (imgBase64) {
                    stopStream();
                    $windowInstance.close(imgBase64);
                }, function () {
                    // error occurred
                });
            }
        };

        $scope.takePhoto = function () {
            photoTaken = true;
            if (videoMode) {
                if (!$scope.isPhotoVisible) {
                    var canvas = document.getElementById(canvasId);
                    var video = document.getElementById(videoId);

                    setMode(true);
                    $timeout(0).then(function () {
                        drawVideoToCanvas(canvas, video);
                    });
                } else {
                    setMode(false);
                }
            } else {
                var imageInput = document.getElementById(imageInputId);
                imageInput.click();
                imageInput.off().on('change', function (e) {
                    var image = e.target.files[0];
                    if (image) {
                        drawImage(image);
                    }
                });
            }
        };

        initController();

        function initController() {
            $timeout(enableImageModalOpenCallback, 200);
            if (videoMode) {
                if (Modernizr.getusermedia) {
                    $timeout(250).then(function () {
                        navigator.mediaDevices.getUserMedia({ video: { facingMode: { ideal: "environment" } } }).then(function (mediaStream) {
                            stream = mediaStream;
                            const videoDocument = document.querySelector('video');
                            const canvas = document.getElementById(canvasId);
                            
                            setCanvasSize(canvas, photoSize.height, photoSize.width);
                            
                            if (videoDocument) {
                                videoDocument.srcObject = stream;
                            } else {
                                $log.error("video element is not found in document");
                            }
                        }, function (error) {
                            $log.error(error);
                        });
                    });
                }

                if (image) {
                    setMode(true);
                    $timeout(100).then(drawImage);
                }
            } else {
                setMode(true);
                $timeout(100).then(drawImage);
            }
        }

        function setMode(isPhotoMode) {
            isPhotoMode = isPhotoMode || !$scope.isPhotoVisible;

            $scope.isPhotoVisible = isPhotoMode;
            $scope.photoButtonText = isPhotoMode ? 'Gjenta et bilde' : 'Ta et bilde'; //ta et bilde - take picture, Gjenta - retake picture

            const canvas = document.getElementById(canvasId);

            if (!isPhotoMode) {
                setCanvasSize(canvas, photoSize.height, photoSize.width);
            }
            
        }

        function setCanvasSize(canvas, height, width){
            if (!canvas) return;
            canvas.height = height;
            canvas.width = width;
        }

        function imageResizeAndConvertToBase64(imageSrc) {
            var deferred = $q.defer();

            var imageElement = new Image();

            imageElement.onload = function () {
                var ratio, maxSize = 1600;

                if (imageElement.width > maxSize) {
                    ratio = maxSize / imageElement.width;

                    imageElement.width = imageElement.width * ratio;
                    imageElement.height = imageElement.height * ratio;
                }

                if (imageElement.height > maxSize) {
                    ratio = maxSize / imageElement.height;

                    imageElement.width = imageElement.width * ratio;
                    imageElement.height = imageElement.height * ratio;
                }

                var canvas = document.createElement("canvas");
                canvas.width = imageElement.width;
                canvas.height = imageElement.height;
                canvas.getContext("2d").drawImage(imageElement, 0, 0, imageElement.width, imageElement.height);
                deferred.resolve(canvas.toDataURL('image/jpeg'));
                canvas.remove();
            };

            imageElement.onerror = function () {
                deferred.reject();
            };

            //Start loading the image
            imageElement.src = imageSrc;

            return deferred.promise;
        }

        function drawImageToCanvas(canvas, img, orientation) {
            var widthHeightChange = orientation > 4;
            var imageWidth = widthHeightChange ? img.height : img.width;
            var imageHeight = widthHeightChange ? img.width : img.height;

            //Save taken photo to recentlyTakenPhotoSrc
            drawCanvas(canvas, img, imageWidth, imageHeight, orientation, true);

            //Set canvas size and image to the correct ratio for the UI
            drawCanvas(canvas, img, imageWidth, imageHeight, orientation, false);
        }

        function drawVideoToCanvas(canvas, video) {
            //Save taken photo to recentlyTakenPhotoSrc
            drawCanvas(canvas, video, video.videoWidth, video.videoHeight, undefined, true);

            //Set canvas size and image to the correct ratio for the UI
            drawCanvas(canvas, video, video.videoWidth, video.videoHeight, undefined, false);
        }

        // Workaround: the canvas on the page serves two functions:
        // 1. Necessary for saving an image from the input of the ,,video" object (camera-stream)
        //    with the resolution of the current size of the canvas (set in the init() and in setMode()).
        //    Right now it is the resolution that is provided in the appCOntants.js under cameraSettings
        // 2. Necessary for displaying the captured/uploaded image for the user in a resolution that can fit
        //    in the computed size of the parent object meanwhile keeping the original ratio to avoid distortion

        function drawCanvas(canvas, object, width, height, orientation, save) {
            canvas.width = save ? canvas.width : getComputedStyle(canvas).width.split('px')[0];
            canvas.height = save ? canvas.height : getComputedStyle(canvas).height.split('px')[0];

            var ratio = Math.min(canvas.width / width, canvas.height / height);
            var x = (canvas.width - width * ratio) / 2;
            var y = (canvas.height - height * ratio) / 2;

            var ctx = canvas.getContext('2d');
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.save();

            if (orientation) {
                loadImage.transformCoordinates(canvas, { orientation: orientation });
            }

            ctx.drawImage(object, 0, 0, width, height, x, y, width * ratio, height * ratio);
            ctx.restore();

            if (save) {
                recentlyTakenPhotoSrc = canvas.toDataURL();
            }
            
        }

        function drawImage() {
            $timeout(function () {
                loadImage.parseMetaData(
                    image,
                    function (data) {
                        var imageElement = new Image();
                        imageElement.src = _.startsWith(image, 'data:image/') ? image : URL.createObjectURL(image);
                        imageElement.onload = function () {
                            var canvas = document.getElementById(canvasId);
                            var orientation = data && data.exif ? data.exif[0x0112] : null;

                            drawImageToCanvas(canvas, imageElement, orientation);
                        };
                    }
                );
            }, 100);
        }

        function stopStream() {
            if (stream) {
                _.forEach(stream.getTracks(), function (track) {
                    track.stop();
                });
            }
        }
    }
})();
